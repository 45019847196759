















































































































import Vue from "vue";

import CustomImage from "@/components/CustomImage.vue";
import DialogImage from "@/components/DialogImage.vue";

export default Vue.extend({
  name: "InfraestruturaMicroFrx",
  components: {
    CustomImage,
    DialogImage,
  },
  data: () => ({
    exemplos: [
      {
        imgSrc: "/img/infraestrutura/micro_frx/aplicacao_01.jpg",
        thumbnailSrc: "/img/infraestrutura/micro_frx/thumb_aplicacao_01.jpg",
        caption: "Exemplo 01",
      },
      {
        imgSrc: "/img/infraestrutura/micro_frx/aplicacao_02.jpg",
        thumbnailSrc: "/img/infraestrutura/micro_frx/thumb_aplicacao_02.jpg",
        caption: "Exemplo 02",
      },
      {
        imgSrc: "/img/infraestrutura/micro_frx/aplicacao_03.jpg",
        thumbnailSrc: "/img/infraestrutura/micro_frx/thumb_aplicacao_03.jpg",
        caption: "Exemplo 03",
      },
      {
        imgSrc: "/img/infraestrutura/micro_frx/aplicacao_04.jpg",
        thumbnailSrc: "/img/infraestrutura/micro_frx/thumb_aplicacao_04.jpg",
        caption: "Exemplo 04",
      },
      {
        imgSrc: "/img/infraestrutura/micro_frx/aplicacao_05.jpg",
        thumbnailSrc: "/img/infraestrutura/micro_frx/thumb_aplicacao_05.jpg",
        caption: "Exemplo 05",
      },
      {
        imgSrc: "/img/infraestrutura/micro_frx/aplicacao_06.jpg",
        thumbnailSrc: "/img/infraestrutura/micro_frx/thumb_aplicacao_06.jpg",
        caption: "Exemplo 06",
      },
      {
        imgSrc: "/img/infraestrutura/micro_frx/aplicacao_07.jpg",
        thumbnailSrc: "/img/infraestrutura/micro_frx/thumb_aplicacao_07.jpg",
        caption: "Exemplo 07",
      },
    ],
  }),
});
