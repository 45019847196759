import { RouteConfig } from "vue-router";

import Infraestrutura from "../views/Infraestrutura.vue";
import InfraestruturaAas from "../views/infraestrutura/InfraestruturaAas.vue";
import InfraestruturaAnaliseTermica from "../views/infraestrutura/InfraestruturaAnaliseTermica.vue";
import InfraestruturaAvu from "../views/infraestrutura/InfraestruturaAvu.vue";
import InfraestruturaCarbonoEnxofre from "../views/infraestrutura/InfraestruturaCarbonoEnxofre.vue";
import InfraestruturaConfocal from "../views/infraestrutura/InfraestruturaConfocal.vue";
import InfraestruturaDrx from "../views/infraestrutura/InfraestruturaDrx.vue";
import InfraestruturaFrx from "../views/infraestrutura/InfraestruturaFrx.vue";
import InfraestruturaIcp from "../views/infraestrutura/InfraestruturaIcp.vue";
import InfraestruturaMev from "../views/infraestrutura/InfraestruturaMev.vue";
import InfraestruturaMicroFrx from "../views/infraestrutura/InfraestruturaMicroFrx.vue";
import InfraestruturaMicrotomografia from "../views/infraestrutura/InfraestruturaMicrotomografia.vue";
import InfraestruturaMla from "../views/infraestrutura/InfraestruturaMla.vue";
import InfraestruturaOptica from "../views/infraestrutura/InfraestruturaOptica.vue";
import InfraestruturaParticulas from "../views/infraestrutura/InfraestruturaParticulas.vue";
import InfraestruturaPorosidade from "../views/infraestrutura/InfraestruturaPorosidade.vue";
import InfraestruturaPreparacoes from "../views/infraestrutura/InfraestruturaPreparacoes.vue";
import InfraestruturaSeparacoes from "../views/infraestrutura/InfraestruturaSeparacoes.vue";

const routes: RouteConfig[] = [
  {
    path: "/infraestrutura",
    name: "Infraestrutura",
    component: Infraestrutura,
    meta: {
      title: "Infraestrutura",
    },
  },
  {
    path: "/infraestrutura/aas",
    name: "InfraestruturaAas",
    component: InfraestruturaAas,
    meta: {
      title: "Infraestrutura - Absorção atômica",
    },
  },
  {
    path: "/infraestrutura/analise-termica",
    name: "InfraestruturaAnaliseTermica",
    component: InfraestruturaAnaliseTermica,
    meta: {
      title: "Infraestrutura - Análise térmica",
    },
  },
  {
    path: "/infraestrutura/avu",
    name: "InfraestruturaAvu",
    component: InfraestruturaAvu,
    meta: {
      title: "Infraestrutura - Via úmida",
    },
  },
  {
    path: "/infraestrutura/carbono_enxofre",
    name: "InfraestruturaCarbonoEnxofre",
    component: InfraestruturaCarbonoEnxofre,
    meta: {
      title: "Infraestrutura - Carbono e Enxofre",
    },
  },
  {
    path: "/infraestrutura/confocal",
    name: "InfraestruturaConfocal",
    component: InfraestruturaConfocal,
    meta: {
      title: "Infraestrutura - Microscopia confocal",
    },
  },
  {
    path: "/infraestrutura/drx",
    name: "InfraestruturaDrx",
    component: InfraestruturaDrx,
    meta: {
      title: "Infraestrutura - Difração de raios X",
    },
  },
  {
    path: "/infraestrutura/frx",
    name: "InfraestruturaFrx",
    component: InfraestruturaFrx,
    meta: {
      title: "Infraestrutura - Fluorescência de raios X",
    },
  },
  {
    path: "/infraestrutura/icp",
    name: "InfraestruturaIcp",
    component: InfraestruturaIcp,
    meta: {
      title: "Infraestrutura - ICP OES",
    },
  },
  {
    path: "/infraestrutura/mev",
    name: "InfraestruturaMev",
    component: InfraestruturaMev,
    meta: {
      title: "Infraestrutura - Microscopia eletrônica MEV",
    },
  },
  {
    path: "/infraestrutura/micro_frx",
    name: "InfraestruturaMicroFrx",
    component: InfraestruturaMicroFrx,
    meta: {
      title: "Infraestrutura - Micro FRX",
    },
  },
  {
    path: "/infraestrutura/microtomografia",
    name: "InfraestruturaMicrotomografia",
    component: InfraestruturaMicrotomografia,
    meta: {
      title: "Infraestrutura - Microtomografia",
    },
  },
  {
    path: "/infraestrutura/mla",
    name: "InfraestruturaMla",
    component: InfraestruturaMla,
    meta: {
      title: "Infraestrutura - Análise de imagens MLA",
    },
  },
  {
    path: "/infraestrutura/optica",
    name: "InfraestruturaOptica",
    component: InfraestruturaOptica,
    meta: {
      title: "Infraestrutura - Microscopia óptica",
    },
  },
  {
    path: "/infraestrutura/particulas",
    name: "InfraestruturaParticulas",
    component: InfraestruturaParticulas,
    meta: {
      title: "Infraestrutura - Tamanho e forma de partículas",
    },
  },
  {
    path: "/infraestrutura/porosidade",
    name: "InfraestruturaPorosidade",
    component: InfraestruturaPorosidade,
    meta: {
      title: "Infraestrutura - Porosidade e densidade",
    },
  },
  {
    path: "/infraestrutura/preparacoes",
    name: "InfraestruturaPreparacoes",
    component: InfraestruturaPreparacoes,
    meta: {
      title: "Infraestrutura - Preparações físicas",
    },
  },
  {
    path: "/infraestrutura/separacoes",
    name: "InfraestruturaSeparacoes",
    component: InfraestruturaSeparacoes,
    meta: {
      title: "Infraestrutura - Separações minerais",
    },
  },
];

export default routes;
